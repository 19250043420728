import React from "react";
import Homescreen from "./LandingPage/Homescreen";

const DefaultScreen = () => {
  return (
    <div>
      <Homescreen />
    </div>
  );
};

export default DefaultScreen;
